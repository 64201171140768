import React from 'react';
import Home from '../Home';

class SignIn extends React.Component {
  constructor() {
    super();
    this.state = { email: '', password: '' };
  }

  onChange(type, value) {
    this.setState({ [type]: value });
  }

  onSubmit(e) {
    e.preventDefault();

    const { email, password } = this.state;
    this.props.signInByEmailPassword(email, password);
  }

  renderUserNotFoundError() {
    return (
      <div className="sign-in-errors">
        Unfortunately we don't recognize that email address or password. Please try again.
        <br />
        If you do not know your password, please contact Carl or Keith to get it reset.
      </div>
    );
  }

  render() {
    const { currentUser, loading, userNotFound } = this.props;

    if (currentUser) {
      return <Home />;
    } else if (loading) {
      return <div>Loading...</div>;
    }
    return (
      <div className="center">
        <h2>Please sign in</h2>
        <form onSubmit={e => this.onSubmit(e)}>
          <label>Email</label>
          <div>
            <input
              type="email"
              onChange={e => this.onChange('email', e.target.value)}
              placeholder="you@nwforestworkers.org"
              value={this.state.email}
            />
          </div>
          <label>Password</label>
          <div>
            <input
              type="password"
              onChange={e => this.onChange('password', e.target.value)}
              value={this.state.password}
            />
          </div>
          {userNotFound && this.renderUserNotFoundError()}
          <div>
            <button className="button secondary-background" type="submit">
              Submit
            </button>
          </div>
        </form>
      </div>
    );
  }
}

SignIn.propTypes = {
  currentUser: React.PropTypes.object,
  children: React.PropTypes.node,
  loading: React.PropTypes.bool,
  signInByEmailPassword: React.PropTypes.func,
  userNotFound: React.PropTypes.bool
};

export default SignIn;
