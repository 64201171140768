import firebase from 'firebase';

const config = {
  apiKey: "AIzaSyANwfWd-kC5k53jxjefHjOxdlx50zQ2Nn4",
  authDomain: "nwforestworkers-f633c.firebaseapp.com",
  databaseURL: "https://nwforestworkers-f633c.firebaseio.com",
  storageBucket: "nwforestworkers-f633c.appspot.com"
};

firebase.initializeApp(config);

export const database = firebase.database();
export const storage = firebase.storage();
