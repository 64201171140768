import React from 'react';

export default function DonateCTA() {
  return (
    <div className="DonateCTA center">
      <div className="container">
        <div className="DonateCTA-content">
          <h3>
            Excellent stewardship requires excellent working conditions. Please help us
            create both in America's forests.
          </h3>
        </div>
      </div>
    </div>
  );
}
