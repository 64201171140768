import React from 'react';
import YouTube from '../ContentPiece/decorators/youtube';
import martha_training from '../../images/martha_training.jpg';
import snow_tree_planters from '../../images/snow_tree_planters.jpg';

export default function MissionAndVision() {
  return (
    <div className="About">
      <div className="About-subtitle secondary-foreground container">
        <h2>
          Empowering forest workers and harvesters of non-timber forest
          products in northern California, Oregon and Washington to improve
          their lives and livelihoods.
        </h2>
      </div>
      <div className="container">
        <YouTube.component decoratedText="https://www.youtube.com/watch?v=9tLTuMEnfvg" />
        <h3 className="MissionAndVision-mission center lg-title">
          The mission of the Northwest Forest Worker Center is to promote forest
          stewardship that is respectful of all workers and harvesters and the
          land.
        </h3>
        <div className="MissionAndVision-image-text-container">
          <div className="MissionAndVision-image">
            <img
              src={snow_tree_planters}
              alt="Our vision is a healthy and just forest sector"
            />
          </div>
          <div className="MissionAndVision-text right">
            <div className="MissionAndVision-vision callout lg-title">
              Our vision <span>is a forest sector that...</span>
            </div>
            <ul>
              <li>
                Treats forest workers and harvesters with dignity and fairness
                regardless of cultural background, country of origin, immigration
                status, race, gender or any other social category;
              </li>
              <li>
                Permits workers and harvesters to participate in decisions that
                directly affect their lives and livelihoods;
              </li>
              <li>
                Respects the skill and ecological knowledge workers and harvesters
                contribute to forest stewardship;
              </li>
              <li>
                Restores and/or maintains forest health.
              </li>
            </ul>
          </div>
        </div>
        <div className="MissionAndVision-image-text-container">
          <div className="MissionAndVision-image only-phone">
            <img
              src={martha_training}
              alt="Our work focuses on improving worker health and forest stewardship"
            />
          </div>
          <div className="MissionAndVision-text left">
            <div className="MissionAndVision-vision callout lg-title">
              Our work <span>focuses on...</span>
            </div>
            <ul>
              <li>
                Educating workers and harvesters, policy makers, agency officials,
                employers and landowners about worker rights, fair working
                conditions, and environmental ethics;
              </li>
              <li>
                Recognizing, valuing and expanding worker and harvester skills in
                stewarding the land;
              </li>
              <li>
                Facilitating multiparty monitoring of forest practices, working
                conditions, policy implementation and conditions on the land;
              </li>
              <li>
                Facilitating worker and harvester input into informing forest
                resource decision making, planning and policy implementation;
              </li>
              <li>
                Seeking enforcement of applicable labor laws and changing and/or
                developing new laws to achieve our mission and vision.
              </li>
            </ul>
          </div>
          <div className="MissionAndVision-image no-phone">
            <img
              src={martha_training}
              alt="Our work focuses on improving worker health and forest stewardship"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
